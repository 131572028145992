import React from "react";

import { Layout } from "@kickstartds/gatsby-theme-kickstartds/src/components/Layout";

import { Html } from "@kickstartds/base/lib/html";
import { Button } from "@kickstartds/base/lib/button";
import { Section } from "@kickstartds/base/lib/section";
import { Headline } from "@kickstartds/base/lib/headline";

function CookiesTemplate() {
  const htmlContent = '<div id="ot-sdk-cookie-policy"></div>';

  return (
    <Layout>
      <Section spaceAfter="none" spaceBefore="small">
        <Headline
          level="h1"
          align="center"
          content="Cookie List"
          subheadline="Info about the cookies in use on our site"
        />
      </Section>

      <Section mode="list" spaceBefore="small" spaceAfter="small">
        <Headline
          level="h2"
          align="left"
          content="Adjust your cookie preferences"
          pageHeader="true"
        />
        <Button
          id="ot-sdk-btn"
          className="ot-sdk-show-settings"
          label="Cookie Settings"
          size="large"
          type="button"
          variant="solid"
        />
      </Section>

      <Section spaceBefore="none">
        <Html html={htmlContent} />
      </Section>
    </Layout>
  );
}
export default CookiesTemplate;
